<template>
  <div class="systemManage-create create-email">
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      :before-close="close"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <div class="form-box">
        <el-form
          label-width="120px"
          style="width: 100%"
          :model="formData"
          ref="form"
          :rules="rules"
          size="small"
        >
          <el-form-item :label="$t('emailConfig.Store')">
            <el-input v-model="formData.storeName" disabled />
          </el-form-item>
          <el-form-item :label="$t('emailConfig.User Name')" prop="userName">
            <el-input v-model="formData.userName" placeholder="" clearable />
          </el-form-item>
          <el-form-item :label="$t('emailConfig.Email')" prop="emailAccount">
            <el-input
              v-model="formData.emailAccount"
              placeholder=""
              clearable
            />
          </el-form-item>
          <!-- 保存按钮 -->
          <div class="form-btn-section">
            <el-button color="#28C76F" size="small" @click="close">{{
              $t("button.Cancel")
            }}</el-button>
            <el-button
              color="#28C76F"
              size="small"
              type="success"
              @click="onSubmit"
            >
              {{ $t("button.Save") }}</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { checkIsEmail } from "@/utils/common.js";
import { getSelectedStoreId, getStoreName } from "../utils/cacheUtil.js";

import { createEmail, updateEmail } from "@/libs/api/email-config";

export default {
  name: "",
  components: {},
  props: {
    emailDialogType: {
      required: true,
      type: String,
      default: "add",
    },
    currentEmail: {
      required: true,
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      title: this.$t('emailConfig.Create Email'),
      formData: {
        storeName: getStoreName(),
        storeId: getSelectedStoreId(),
        userName: "",
        emailAccount: "",
      },
      rules: {
        userName: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("formValidate.maxLength"),
            trigger: "blur",
          },
        ],
        emailAccount: [
          { validator: this.checkEmail, trigger: "blur" },
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("formValidate.maxLength"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    dataA: function (val) {},
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.emailDialogType == "add") {
          this.formData = {
            storeName: getStoreName(),
            storeId: getSelectedStoreId(),
            userName: "",
            emailAccount: "",
            emailAccountId: "",
          };
          this.title = this.$t('emailConfig.Create Email')
        } else {
          this.formData = {
            storeName: getStoreName(),
            storeId: getSelectedStoreId(),
            userName: this.currentEmail.userName,
            emailAccount: this.currentEmail.emailAccount,
            emailAccountId: this.currentEmail.emailAccountId,
          };
          this.title = this.$t('emailConfig.Edit Email')
        }
      }
    },
  },
  methods: {
    // 校验邮箱
    checkEmail(rule, value, callback) {
      console.log("邮箱value,rule", value, rule, callback);
      if (!value) {
        return callback(new Error(this.$t("formValidate.required fields")));
      } else {
        let isEmail = checkIsEmail(value);
        if (!isEmail) {
          return callback(new Error("Please enter the correct email address"));
        } else {
          callback();
        }
      }
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log("表单校验通过");
          this.createEmailFun();
        } else {
          // loading.close();
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 创建邮箱
    createEmailFun() {
      if (this.emailDialogType == "add") {
        createEmail(this.formData).then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.$message({
              message: this.$t("commonMessage.CreateSucess"),
              type: "success",
            });
            this.close();
            this.$emit("reGetEmailList");
          } else {
            // this.$message.error($t('commonMessage.CreateError'));
            this.$message.error(res.data.message);
          }
        });
      } else {
        updateEmail(this.formData).then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.$message({
              message: this.$t("commonMessage.ModifySucess"),
              type: "success",
            });
            this.close();
            this.$emit("reGetEmailList");
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    showmessage() {
      this.$message({
        message: "please select img!",
        type: "success",
      });
      this.$message({
        message: "please select img!",
        type: "warning",
      });
      this.$message.error("错误提示");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>