<template>
  <div class="common-table-list email-config">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link>
      <span> > </span>
      <span>{{ $t("System Configuration") }} > </span>
      <span style="color: #3461ff">{{ $t("Email Configuration") }}</span>
    </div>
    <div class="email-content card">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane :label="$t('emailConfig.Email Account')" name="first">
          <div class="card">
            <div class="handleBtn">
              <div>
                <span class="text"
                  >{{ $t("templateInfo.Records") }}:
                  {{ emailAccountPageParams.total }}</span
                >
                <el-select
                  v-model="emailAccountPageParams.storeQueryType"
                  @change="getEmailListData"
                  size="small"
                  style="margin-left: 10px"
                >
                  <el-option
                    v-for="(item, i) in storeTypeList()"
                    :key="i"
                    :label="item.name"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div>
                <el-button type="primary" size="small" @click="handleAdd">
                  <i class="el-icon-plus" />
                  {{ $t("button.New") }}
                </el-button>
                <el-button
                  type="danger"
                  size="small"
                  @click="handleBatchDel('emailBatchDelete')"
                  :disabled="currentRowList.length < 1"
                >
                  <i class="el-icon-delete" />
                  {{ $t("button.Batch Delete") }}
                </el-button>
              </div>
            </div>
          </div>

          <!-- 表格 -->
          <el-table
            ref="normalTable"
            :data="dataList"
            style="width: 100%"
            @sort-change="handleSort"
            @selection-change="handleSelectionChange"
            max-height="500"
          >
            <el-table-column align="center" type="selection" fixed>
              <el-table-column align="center" width="55" type="selection">
              </el-table-column>
            </el-table-column>
            <el-table-column
              prop="storeName"
              min-width="130"
              :label="$t('emailConfig.Store Name')"
              header-align="center"
            >
              <el-table-column prop="storeName" min-width="130" align="center">
                <template slot="header">
                  <el-input
                    size="mini"
                    v-model="emailAccountPageParams.storeName"
                    @input="getEmailListData"
                    clearable
                  ></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              prop="userName"
              min-width="130"
              :label="$t('emailConfig.User Name')"
              sortable="custom"
              header-align="center"
            >
              <el-table-column prop="userName" min-width="130" align="center">
                <template slot="header">
                  <el-input
                    size="mini"
                    v-model="emailAccountPageParams.userName"
                    @input="getEmailListData"
                    placeholder=""
                    clearable
                  ></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              prop="emailAccount"
              min-width="130"
              :label="$t('emailConfig.Email')"
              sortable="custom"
              header-align="center"
            >
              <el-table-column
                prop="emailAccount"
                min-width="130"
                align="center"
              >
                <template slot="header">
                  <el-input
                    size="mini"
                    v-model="emailAccountPageParams.emailAccount"
                    @input="getEmailListData"
                    placeholder=""
                    clearable
                  ></el-input>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              prop="updateTime"
              :label="$t('emailConfig.Update Time')"
              sortable="custom"
              width="230"
              header-align="center"
            >
              <el-table-column prop="updateTime" width="230" align="center">
                <template slot="header" slot-scope="scope">
                  <el-date-picker
                    v-model="emailAccountPageParams.updateTime"
                    type="daterange"
                    size="mini"
                    @change="handleDateChange('emailAccount')"
                    placement="bottom-start"
                    value-format="yyyy-MM-dd"
                  />
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              fixed="right"
              :label="$t('emailConfig.Action')"
              width="120"
              header-align="center"
            >
              <el-table-column align="center" width="120">
                <template slot="header"></template>
                <template slot="default" slot-scope="{ row }">
                  <div class="operate">
                    <span class="table-icon" @click="handleEmailEdit(row)">
                      <i class="el-icon-edit-outline"></i>
                    </span>
                    <span
                      class="table-icon"
                      @click="handleDelete(row, 'emailDelete')"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

          <!-- 页码 -->
          <pagination
            :tableInfo="emailAccountPageParams"
            @changePage="getEmailListData"
          />

          <createEmailAccount
            ref="createEmailAccount"
            :currentEmail="currentEmail"
            :emailDialogType="emailDialogType"
            @reGetEmailList="reGetEmailList"
          ></createEmailAccount>
        </el-tab-pane>
        <el-tab-pane :label="$t('emailConfig.Email Strategy')" name="second">
          <div class="card">
            <div class="handleBtn">
              <div>
                <span class="text"
                  >{{ $t("templateInfo.Records") }}:
                  {{ emailStrategyPageParams.total }}</span
                >
                <el-select
                  v-model="emailStrategyPageParams.storeQueryType"
                  @change="getStrategyListData"
                  size="small"
                  style="margin-left: 10px"
                >
                  <el-option
                    v-for="(item, i) in storeTypeList()"
                    :key="i"
                    :label="item.name"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div>
                <el-button
                  type="primary"
                  size="small"
                  @click="handleAddEmailStrategy"
                >
                  <i class="el-icon-plus" />
                  {{ $t("button.New") }}
                </el-button>
                <el-button
                  type="danger"
                  size="small"
                  @click="handleBatchDel('strategyBatchDelete')"
                  :disabled="currentRowStrategyList.length < 1"
                >
                  <i class="el-icon-delete" />
                  {{ $t("button.Batch Delete") }}
                </el-button>
              </div>
            </div>
          </div>
          <div class="bd">
            <!-- 表格 -->
            <el-table
              ref="normalTable"
              :data="dataList2"
              style="width: 100%"
              @sort-change="handleSortStrategy"
              @selection-change="handleSelectionStrategyChange"
              max-height="500"
            >
              <el-table-column align="center" type="selection" fixed>
                <el-table-column
                  align="center"
                  width="55"
                  type="selection"
                  style="display: none"
                >
                </el-table-column>
              </el-table-column>
              <!-- Address      -->
              <el-table-column
                prop="strategyName"
                min-width="150"
                :label="$t('emailConfig.Push Strategy')"
                sortable="custom"
                header-align="center"
              >
                <el-table-column
                  prop="strategyName"
                  min-width="150"
                  align="center"
                >
                  <template slot="header" slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model="emailStrategyPageParams.strategyName"
                      @input="getStrategyListData"
                      placeholder=""
                      clearable
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                prop="messageTypeName"
                min-width="150"
                :label="$t('emailConfig.Message Type')"
                sortable="custom"
                header-align="center"
              >
                <el-table-column
                  prop="messageTypeName"
                  min-width="150"
                  align="center"
                >
                  <template slot="header" slot-scope="scope">
                    <el-select
                      size="mini"
                      v-model="emailStrategyPageParams.messageType"
                      clearable
                      @change="getStrategyListData"
                    >
                      <el-option
                        v-for="item in messageTypeList"
                        :key="item.codeId"
                        :label="item.codeName"
                        :value="item.codeId"
                      />
                    </el-select>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                prop="receiver"
                min-width="160"
                :label="$t('emailConfig.Receiver')"
                sortable="custom"
                header-align="center"
              >
                <el-table-column prop="receiver" min-width="160" align="center">
                  <template slot="header" slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model="emailStrategyPageParams.receiver"
                      @input="getStrategyListData"
                      placeholder=""
                      clearable
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                prop="cc"
                min-width="160"
                :label="$t('emailConfig.CC')"
                sortable="custom"
                header-align="center"
              >
                <el-table-column prop="cc" min-width="160" align="center">
                  <template slot="header" slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model="emailStrategyPageParams.cc"
                      @input="getStrategyListData"
                      placeholder=""
                      clearable
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                prop="bcc"
                min-width="160"
                :label="$t('emailConfig.Secret Messenger')"
                sortable="custom"
                header-align="center"
              >
                <el-table-column
                  prop="bcc"
                  min-width="160"
                  align="center"
                >
                  <template slot="header" slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model="emailStrategyPageParams.bcc"
                      @input="getStrategyListData"
                      placeholder=""
                      clearable
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                prop="followStoreName"
                min-width="160"
                :label="$t('emailConfig.Follow Store')"
                sortable="custom"
                header-align="center"
              >
                <el-table-column
                  prop="followStoreName"
                  min-width="160"
                  align="center"
                >
                  <template slot="header" slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model="emailStrategyPageParams.followStoreName"
                      @input="getStrategyListData"
                      placeholder=""
                      clearable
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table-column>
              <!-- Push Strategy、Addressee、CC: 、Secret Messenger、Follow Store、Update 、Action	 -->

              <el-table-column
                prop="create_time"
                :label="$t('emailConfig.Create Time')"
                sortable="custom"
                width="230"
                header-align="center"
              >
                <el-table-column prop="updateTime" width="230" align="center">
                  <template slot="header" slot-scope="scope">
                    <el-date-picker
                      v-model="emailStrategyPageParams.updateTime"
                      type="daterange"
                      size="mini"
                      @change="handleDateChange('emailStrategy')"
                      placement="bottom-start"
                      value-format="yyyy-MM-dd"
                    />
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column
                fixed="right"
                :label="$t('emailConfig.Action')"
                width="120"
                header-align="center"
              >
                <el-table-column align="center" width="120">
                  <template slot="header"></template>
                  <template slot="default" slot-scope="{ row }">
                    <div class="operate">
                      <span class="table-icon" @click="handleDelete(row, 'strategyDelete')">
                        <i class="el-icon-delete"></i>
                      </span>
                      <span class="table-icon" @click="handleEdit(row)">
                        <i class="el-icon-edit-outline"></i>
                      </span>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>

            <!-- 页码 -->
            <pagination
              :tableInfo="emailStrategyPageParams"
              @changePage="getStrategyListData"
            />

            <createEmailStrategy
              :currentStrategy="currentStrategy"
              :strategyDialogType="strategyDialogType"
              ref="createEmailStrategy"
              @reGetStrategyList="reGetStrategyList"
            ></createEmailStrategy>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <confirm-dialog
      :dialogVisible="isVisibleConfirmDialog"
      @handleClose="handelConfirmDialog"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>
  </div>
</template>
<script>
import pagination from "@/components/pagination";
import ConfirmDialog from "@/components/confirm-dialog/index.vue";

import createEmailAccount from "./components/createEmailAccount";
import createEmailStrategy from "./components/createEmailStrategy";

import { getSelectedStoreId } from "./utils/cacheUtil.js";
import { storeTypeList } from "@/libs/enum";

import {
  getEmailList,
  emailDelete,
  emailBatchDelete,
  getStrategyList,
  getMessageTypeList,
  strategyDelete,
  strategyBatchDelete,
} from "@/libs/api/email-config";

export default {
  name: "",
  components: {
    pagination,
    ConfirmDialog,
    createEmailAccount,
    createEmailStrategy,
  },
  props: {
    location: {
      type: String,
      default: "default",
    },
  },
  provide() {
    return {
      storeQueryType: this.emailAccountPageParams.storeQueryType,
    };
  },
  data() {
    return {
      messageTypeList: [],
      storeTypeList,
      activeName: "first",
      emailAccountPageParams: {
        storeId: getSelectedStoreId(),
        storeQueryType: 2,
        isEffect: 0,
        current: 1, //当前页
        size: 10, //单页显示最大数
        total: 0,
        pageSizeArr: ["10", "20", "50", "100", "200", "500", "1000"],
        storeName: "",
        userName: "",
        emailAccount: "",
        updateTime: "",
        updateTimeStart: "",
        updateTimeEnd: "",
      },
      emailStrategyPageParams: {
        storeId: getSelectedStoreId(),
        storeQueryType: 2,
        isEffect: 0,
        current: 1, //当前页
        size: 10, //单页显示最大数
        total: 0,
        pageSizeArr: ["10", "20", "50", "100", "200", "500", "1000"],
        storeName: "",
        userName: "",
        emailAccount: "",
        updateTime: "",
        updateTimeStart: "",
        updateTimeEnd: "",
        strategyName: "", // 策略名称
        followStoreName: "", // 关注门店名称
        bcc: ''
      },
      dataList: [],
      dataList2: [],
      currentConfirmType: "",
      currentRow: {},
      currentRowList: [],
      currentRowStrategyList: [],
      isVisibleConfirmDialog: false,
      confirmObj: {
        type: this.$t("taskManagerInfo.Tips"),
        cancelText: this.$t("taskManagerInfo.Cancel"),
        confirmText: this.$t("taskManagerInfo.Sure"),
        content: this.$t(
          "taskManagerInfo.Are you sure to delete the selected task？"
        ),
      },
      currentEmail: {},
      emailDialogType: "add",
      currentStrategy: {},
      strategyDialogType: "add",
    };
  },
  created() {},
  mounted() {
    this.getEmailListData();
    this.getStrategyListData();
    this.getMessageTypeListData();
  },
  computed: {
    dataA: function (val) {},
  },
  watch: {
    dataA: function (val) {},
  },
  methods: {
    // 获取邮箱账号数据
    getEmailListData() {
      if (this.emailAccountPageParams.updateTime) {
        this.emailAccountPageParams.updateTimeStart =
          this.emailAccountPageParams.updateTime[0] + " 00:00:00";
        this.emailAccountPageParams.updateTimeEnd =
          this.emailAccountPageParams.updateTime[1] + " 23:59:59";
      } else {
        this.emailAccountPageParams.updateTimeStart = "";
        this.emailAccountPageParams.updateTimeEnd = "";
      }
      getEmailList(this.emailAccountPageParams).then((res) => {
        console.log(res.data);
        console.log(res.data.data.total);
        this.emailAccountPageParams.total = res.data.data.total;
        this.dataList = res.data.data.records;
      });
    },
    reGetEmailList() {
      this.getEmailListData();
    },
    reGetStrategyList() {
      this.getStrategyListData();
    },
    // 获取邮箱策略列表数据
    getStrategyListData() {
      if (this.emailStrategyPageParams.updateTime) {
        this.emailStrategyPageParams.updateTimeStart =
          this.emailStrategyPageParams.updateTime[0] + " 00:00:00";
        this.emailStrategyPageParams.updateTimeEnd =
          this.emailStrategyPageParams.updateTime[1] + " 23:59:59";
      } else {
        this.emailStrategyPageParams.updateTimeStart = "";
        this.emailStrategyPageParams.updateTimeEnd = "";
      }
      getStrategyList(this.emailStrategyPageParams).then((res) => {
        console.log(res.data);
        console.log(res.data.data.total);
        this.emailStrategyPageParams.total = res.data.data.total;
        this.dataList2 = res.data.data.records;
      });
    },

    // 获取消息类型列表
    getMessageTypeListData() {
      let params = {
        cateId: 34,
      };
      getMessageTypeList(params).then((res) => {
        console.log(res.data);
        this.messageTypeList = res.data.data;
      });
    },

    // 编辑邮箱账号
    handleEmailEdit(row) {
      console.log(row);
      this.currentEmail = row;
      this.emailDialogType = "edit";
      this.$refs.createEmailAccount.open();
    },

    // 编辑邮箱策略
    handleEdit(row) {
      console.log(row);
      this.currentStrategy = row;
      this.strategyDialogType = "edit";
      this.$refs.createEmailStrategy.open();
    },

    handelConfirmDialog(val) {
      const { currentConfirmType } = this;
      if (val === "confirm") {
        if (currentConfirmType === "emailDelete") {
          this.doEmailDelete();
        }
        if (currentConfirmType === "emailBatchDelete") {
          this.doEmailBatchDel();
        }

        if (currentConfirmType === "strategyDelete") {
          this.doStrategyDelete();
        }

        if (currentConfirmType === "strategyBatchDelete") {
          this.doStrategyBatchDel();
        }

      } else {
        this.isVisibleConfirmDialog = false;
      }
    },
    // 删除邮箱账号
    doEmailDelete() {
      const params = {
        emailAccountId: this.currentRow.emailAccountId,
      };
      emailDelete(params).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$message.success(this.$t("commonMessage.DeleteSucess"));
          this.isVisibleConfirmDialog = false;
          this.getEmailListData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 批量删除邮箱账号
    doEmailBatchDel() {
      const params = {
        emailAccountIds: this.currentRowList,
      };
      emailBatchDelete(params).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$message.success(this.$t("commonMessage.DeleteSucess"));
          this.isVisibleConfirmDialog = false;
          this.getEmailListData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 删除邮箱策略
    doStrategyDelete() {
      const params = {
        emailStrategyId: this.currentRow.emailStrategyId,
      };
      strategyDelete(params).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$message.success(this.$t("commonMessage.DeleteSucess"));
          this.isVisibleConfirmDialog = false;
          this.getStrategyListData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 批量删除邮箱策略
    doStrategyBatchDel() {
      const params = {
        emailStrategyIds: this.currentRowStrategyList,
      };
      strategyBatchDelete(params).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$message.success(this.$t("commonMessage.DeleteSucess"));
          this.isVisibleConfirmDialog = false;
          this.getStrategyListData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    handleDelete(row, type) {
      console.log(row);
      this.isVisibleConfirmDialog = true;
      this.currentConfirmType = type;
      this.currentRow = row;
    },
    // 多选功能 邮箱账号
    handleSelectionChange(selection) {
      console.log(selection);

      this.currentRowList = selection.map((item) => {
        return item.emailAccountId;
      });

      console.log(this.currentRowList);
    },

    // 邮箱策略多选功能
    handleSelectionStrategyChange(selection) {
      console.log(selection);
      this.currentRowStrategyList = selection.map((item) => {
        return item.emailStrategyId;
      });

      console.log(this.currentRowStrategyList);
    },

    handleClick() {},
    // 日期发生变化
    handleDateChange(type) {
      if (type == "emailAccount") {
        this.getEmailListData();
      } else {
        this.getStrategyListData();
      }
    },
    // 新增邮箱
    handleAdd() {
      this.emailDialogType = "add";
      this.currentEmail = {};
      this.$refs.createEmailAccount.open();
    },
    // 批量删除邮箱账号
    handleBatchDel(type) {
      this.isVisibleConfirmDialog = true;
      this.currentConfirmType = type;
    },
    // 获取选中的数量
    selection() {
      return this.$refs.normalTable && this.$refs.normalTable.selection.length;
    },
    // 邮箱账号排序
    handleSort(column) {
      console.log("排序", column);
      let arr = [];
      if (column.order) {
        let str1 = column.order === "ascending" ? "asc" : "desc";
        let columnProp = "";
        if (column.prop == "userName") {
          columnProp = "user_name";
        }
        if (column.prop == "emailAccount") {
          columnProp = "email_account";
        }
        if (column.prop == "updateTime") {
          columnProp = "update_time";
        }
        str1 = str1 + ":" + columnProp;
        arr.push(str1);
      } else {
        arr = [];
      }
      this.emailAccountPageParams.orders = arr;
      this.getEmailListData();
    },

    // 邮箱策略排序
    handleSortStrategy(column){
      let arr = [];
      if (column.order) {
        let str1 = column.order === "ascending" ? "asc" : "desc";
        let columnProp = "";
        if (column.prop == "strategyName") {
          columnProp = "strategy_name";
        }
        if (column.prop == "messageTypeName") {
          columnProp = "message_type";
        }
        if (column.prop == "receiver") {
          columnProp = "receiver";
        }
        if (column.prop == "cc") {
          columnProp = "cc";
        }
        if (column.prop == "bcc") {
          columnProp = "bcc";
        }
        if (column.prop == "followStoreName") {
          columnProp = "follow_store";
        }
        if (column.prop == "updateTime") {
          columnProp = "update_time";
        }
        str1 = str1 + ":" + columnProp;
        arr.push(str1);
      } else {
        arr = [];
      }
      this.emailStrategyPageParams.orders = arr;
      this.getStrategyListData()
    },

    // 邮箱策略---新增
    handleAddEmailStrategy() {
      this.strategyDialogType = "add";
      this.currentStrategy = {};
      this.$refs.createEmailStrategy.open();
    },
    
  },
};
</script>
<style lang="scss" scoped>
.email-config {
  .email-content {
    padding: 0 15px;
  }
  .handleBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.email-content ::v-deep {
  .el-table
    .el-table__header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    display: none;
  }
}
</style>