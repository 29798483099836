import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

function getEmailList(data) {
  // 获取邮件账号列表
  return router({
    url: baseUrl+"email/account/v1/getList",
    data: data,
    method: "POST",
  });
}

function createEmail(data) {
  // 创建邮件账号
  return router({
    url: baseUrl+"email/account/v1/create",
    data: data,
    method: "POST",
  });
}


function updateEmail(data) {
  // 修改邮件账号
  return router({
    url: baseUrl+"email/account/v1/update",
    data: data,
    method: "POST",
  });
}

function emailDelete(data) {
  // 删除邮箱账号
  return router({
    url: baseUrl+"email/account/v1/physicalDelete",
    data: data,
    method: "POST",
  });
}

function emailBatchDelete(data) {
  // 批量删除邮箱账号
  return router({
    url: baseUrl+"email/account/v1/batchPhysicalDelete",
    data: data,
    method: "POST",
  });
}


function getStrategyList(data) {
  // 获取邮件账号列表
  return router({
    url: baseUrl+"email/strategy/v1/getList",
    data: data,
    method: "POST",
  });
}


function getMessageTypeList(data) {
  // 获取消息类型列表
  return router({
    url: baseUrl+"code/v1/getList",
    data: data,
    method: "POST",
  });
}


function createStrategy(data) {
  // 创建邮件策略
  return router({
    url: baseUrl+"email/strategy/v1/create",
    data: data,
    method: "POST",
  });
}


function getLang(data) {
  // 获取当前语言的id
  return router({
    url: baseUrl+"/language/v1/getList",
    data: data,
    method: "POST",
  });
}


function updateStrategy(data) {
  // 修改邮件策略
  return router({
    url: baseUrl+"email/strategy/v1/update",
    data: data,
    method: "POST",
  });
}


function strategyDelete(data) {
  // 删除邮件策略
  return router({
    url: baseUrl+"email/strategy/v1/physicalDelete",
    data: data,
    method: "POST",
  });
}


function strategyBatchDelete(data) {
  // 批量删除邮件策略
  return router({
    url: baseUrl+"email/strategy/v1/batchPhysicalDelete",
    data: data,
    method: "POST",
  });
}
  

export {
  getEmailList,
  createEmail,
  updateEmail,
  emailDelete,
  emailBatchDelete,

  getStrategyList,
  getMessageTypeList,
  createStrategy,
  updateStrategy,
  getLang,
  strategyDelete,
  strategyBatchDelete,
}