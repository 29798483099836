<template>
  <div class="systemManage-create create-email">
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      :before-close="close"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="form-box">
        <el-form
          label-width="170px"
          style="width: 100%"
          :model="formData"
          ref="form"
          :rules="rules"
          size="small"
        >
          <el-form-item
            :label="$t('emailConfig.Push Strategy')"
            prop="strategyName"
          >
            <el-input
              v-model="formData.strategyName"
              placeholder=""
              clearable
            />
          </el-form-item>
          <el-form-item
            :label="$t('emailConfig.Message Type')"
            prop="messageType"
          >
            <el-select
              v-model="formData.messageType"
              @change="messageTypeChange"
              size="small"
              clearable
              multiple
            >
              <el-option
                v-for="item in messageTypeList"
                :key="item.codeId"
                :label="item.codeName"
                :value="item.codeId"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('emailConfig.Receiver')" prop="receiver">
            <div class="d-flex">
              <el-select
                v-model="formData.receiverList"
                multiple
                clearable
                :disabled="formData.receiverList.length < 1"
                style="flex: 1; margin-right: 10px"
                @visible-change="visibleChange"
                ref="receiverRef"
              >
              </el-select>
              <el-button
                style="height: 32px"
                type="primary"
                size="small"
                @click="handleSelectEmail('receiver')"
                ><i class="el-icon-plus" />
                {{ $t("emailConfig.Email list") }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item :label="$t('emailConfig.CC')" prop="cc">
            <div class="d-flex">
              <el-select
                v-model="formData.ccList"
                multiple
                clearable
                :disabled="formData.ccList.length < 1"
                style="flex: 1; margin-right: 10px"
                @visible-change="visibleChange"
                ref="ccRef"
              >
              </el-select>
              <el-button
                type="primary"
                size="small"
                @click="handleSelectEmail('cc')"
                ><i class="el-icon-plus" />
                {{ $t("emailConfig.Email list") }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item :label="$t('emailConfig.Secret Messenger')" prop="bcc">
            <div class="d-flex">
              <el-select
                v-model="formData.bccList"
                multiple
                clearable
                :disabled="formData.bccList.length < 1"
                style="flex: 1; margin-right: 10px"
                @visible-change="visibleChange"
                ref="bccRef"
              >
              </el-select>
              <el-button
                type="primary"
                size="small"
                @click="handleSelectEmail('bcc')"
                ><i class="el-icon-plus" />
                {{ $t("emailConfig.Email list") }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('emailConfig.Follow Store')"
            prop="followStoreName"
          >
            <el-input
              v-model="formData.followStoreName"
              placeholder=""
              suffix-icon="el-icon-arrow-down"
              readonly
              class="follow-store"
              @focus="handleSelectStore"
            />
          </el-form-item>
          <el-form-item
            :label="$t('emailConfig.Send Interval(minutes)')"
            prop="sendInterval"
          >
            <el-input-number
              v-model="formData.sendInterval"
              :min="1"
              :step="1"
              :precision="0"
              size="small"
              style="width: 100%"
            ></el-input-number>
          </el-form-item>
          <!-- 保存按钮 -->
          <div class="form-btn-section">
            <el-button color="#28C76F" size="small" @click="close">{{
              $t("button.Cancel")
            }}</el-button>
            <el-button
              color="#28C76F"
              size="small"
              type="success"
              @click="onSubmit"
            >
              {{ $t("button.Save") }}</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- 选择门店 -->
    <selectStore
      ref="selectStoreRef"
      :multiple="true"
      @confirmSelect="handleSelectStoreConfirm"
    ></selectStore>

    <!-- 邮箱列表 -->
    <emailList
      ref="emailListRef"
      :selectedEmail="formData.receiverList"
      @submitSelectedData="submitSelectedData"
    ></emailList>
  </div>
</template>
<script>
import selectStore from "@/components/select-store/index";
import emailList from "./emailList.vue";

import { getSelectedStoreId } from "../utils/cacheUtil.js";

import {
  getMessageTypeList,
  createStrategy,
  updateStrategy,
  getLang,
} from "@/libs/api/email-config";

export default {
  name: "",
  components: {
    selectStore,
    emailList,
  },
  props: {
    strategyDialogType: {
      required: true,
      type: String,
      default: "add",
    },
    currentStrategy: {
      required: true,
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      title: this.$t("emailConfig.New Email Strategy"),
      formData: {
        storeId: getSelectedStoreId(),
        followStoreName: "",
        followStore: "",
        sendInterval: "",
        strategyName: "",
        messageType: [],
        receiverList: [],
        receiver: "",
        ccList: [],
        cc: "",
        bccList: [],
        bcc: "",
        isEffect: 0,
        useLanguage: "",
      },
      messageTypeList: [],
      rules: {
        followStoreName: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "change",
          },
        ],
        sendInterval: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        strategyName: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("formValidate.maxLength"),
            trigger: "blur",
          },
        ],
        messageType: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        receiver: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "change",
          },
        ],
      },
      emailListType: "",
    };
  },
  created() {},
  mounted() {
    this.getMessageTypeListData();
    this.getLangData();
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
    dataA: function (val) {},
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.strategyDialogType == "add") {
          this.formData.emailStrategyId = "";
          this.formData.followStore = "";
          this.formData.followStoreName = "";
          this.formData.sendInterval = "";
          this.formData.strategyName = "";
          this.formData.messageType = [];
          this.formData.receiverList = [];
          this.formData.receiver = "";
          this.formData.ccList = [];
          this.formData.cc = "";
          this.formData.bccList = [];
          this.formData.bcc = "";
          this.title = this.$t("emailConfig.New Email Strategy");
        } else {
          this.formData = { ...this.formData, ...this.currentStrategy };
          this.formData.messageType = this.currentStrategy.messageType.split(",").map((item) => item * 1)
          this.formData.receiverList = this.formData.receiver.split(",");
          if (this.formData.cc)
            this.formData.ccList = this.formData.cc.split(",");
          if (this.formData.bcc)
            this.formData.bccList = this.formData.bcc.split(",");
          console.log(this.formData);

          this.title = this.$t("emailConfig.Edit Email Strategy");
        }
      }
    },
    dataA: function (val) {},
  },
  methods: {
    visibleChange(e) {
      console.log(e);
      this.$refs.receiverRef.visible = false;
      this.$refs.ccRef.visible = false;
      this.$refs.bccRef.visible = false;
    },

    messageTypeChange(data) {
      console.log(data);
      if (this.formData.messageTypeName) {
        this.formData.messageTypeName;
      }
    },
    getLangData() {
      getLang().then((res) => {
        console.log(res.data.data);
        let currentLang = {};
        if (this.language == "zh") {
          currentLang = res.data.data.filter(
            (item) => item.languageCode == "zh_CN"
          );
        } else {
          currentLang = res.data.data.filter(
            (item) => item.languageCode == "en_US"
          );
        }
        console.log(currentLang);
        this.formData.useLanguage = currentLang[0].languageId;
      });
    },

    // 打开
    open() {
      this.dialogVisible = true;
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.formData.receiverList = [];
      this.formData.ccList = [];
      this.formData.bccList = [];
      this.$refs.form.resetFields();
    },
    // 选择门店--打开弹窗
    handleSelectStore() {
      this.$refs.selectStoreRef.open();
    },
    // 选择门店---确定
    handleSelectStoreConfirm(arr) {
      console.log(arr);
      let ids = arr.map((item) => item.storeId).join(",");
      let followStore = ids;
      let followStoreName = arr.map((item) => item.storeName).join(",");
      this.formData = { ...this.formData, followStore };
      this.formData = { ...this.formData, followStoreName };
      console.log(ids, arr);
      console.log(this.formData);
    },
    // 选择邮箱列表
    handleSelectEmail(type) {
      this.emailListType = type;
      this.$refs.emailListRef.open();
    },
    // 提交
    onSubmit() {
      console.log(this.formData);

      this.formData.receiver = this.formData.receiverList.join(",");
      this.formData.cc = this.formData.ccList.join(",");
      this.formData.bcc = this.formData.bccList.join(",");
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log("表单校验通过");
          if (this.formData.emailStrategyId) {
            this.updateEmail();
          } else {
            this.createEmail();
          }
        } else {
          loading.close();
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 创建邮箱策略
    createEmail() {
      createStrategy(this.formData).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$message({
            message: this.$t("commonMessage.CreateSucess"),
            type: "success",
          });
          this.close();
          this.$emit("reGetStrategyList");
        }
      });
    },
    // 修改邮箱策略
    updateEmail() {
      updateStrategy(this.formData).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$message({
            message: this.$t("commonMessage.ModifySucess"),
            type: "success",
          });
          this.close();
          this.$emit("reGetStrategyList");
        }
      });
    },
    showmessage() {
      this.$message({
        message: "please select img!",
        type: "success",
      });
      this.$message({
        message: "please select img!",
        type: "warning",
      });
      this.$message.error("错误提示");
    },
    // 获取消息类型列表
    getMessageTypeListData() {
      let params = {
        cateId: 34,
      };
      getMessageTypeList(params).then((res) => {
        console.log(res.data);
        this.messageTypeList = res.data.data;
        // this.formData.messageType = this.formData.messageType
      });
    },
    submitSelectedData(data) {
      console.log(data);
      data.map((item) => {
        if (this.emailListType == "receiver") {
          if (this.formData.receiverList.indexOf(item.emailAccount) < 0) {
            this.formData.receiverList.push(item.emailAccount);
          }
        }
        if (this.emailListType == "cc") {
          if (this.formData.ccList.indexOf(item.emailAccount) < 0) {
            this.formData.ccList.push(item.emailAccount);
          }
        }
        if (this.emailListType == "bcc") {
          if (this.formData.bccList.indexOf(item.emailAccount) < 0) {
            this.formData.bccList.push(item.emailAccount);
          }
        }

        // console.log(item);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.create-email {
  .form-box {
    width: 500px;
  }
  .follow-store {
    ::v-deep .el-input__inner {
      cursor: pointer;
    }
  }
}
</style>