<template>
  <div class="email-list">
    <el-dialog
      v-model="dialogVisible"
      :title="$t('emailConfig.Email list')"
      width="700px"
      :before-close="close"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-table
        ref="singleTableRef"
        :data="dataList"
        style="width: 100%"
        height="400"
        highlight-current-row
        @current-change="handleCheck"
        @selection-change="handleSelectionChange"
        @sort-change="handleSort"
        border
      >
        <el-table-column align="center" type="selection" fixed>
          <el-table-column align="center" width="55" type="selection">
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="no"
          min-width="80"
          :label="$t('emailConfig.No')"
          header-align="center"
        >
          <el-table-column prop="no" min-width="80" align="center">
            <template slot="header"></template>
            <template slot-scope="scope">
              {{(pageParams.current - 1) * pageParams.size + scope.$index + 1}}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="userName"
          min-width="140"
          :label="$t('emailConfig.User Name')"
          header-align="center"
          sortable="custom"
        >
          <el-table-column prop="userName" min-width="140" align="center">
            <template slot="header" slot-scope="scope">
              <el-input
                size="mini"
                v-model="pageParams.userName"
                @input="getData"
                placeholder=""
                clearable
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="emailAccount"
          min-width="140"
          :label="$t('emailConfig.Email')"
          header-align="center"
          sortable="custom"
        >
          <el-table-column prop="emailAccount" min-width="140" align="center">
            <template slot="header" slot-scope="scope">
              <el-input
                size="mini"
                v-model="pageParams.emailAccount"
                @input="getData"
                placeholder=""
                clearable
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />
      <!-- 保存按钮 -->
      <div class="form-btn-section">
        <el-button color="#28C76F" size="small" @click="close">{{
          $t("button.Cancel")
        }}</el-button>
        <el-button
          color="#28C76F"
          size="small"
          type="success"
          @click="onSubmit"
        >
          {{ $t("button.Save") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "@/components/pagination";

import { getSelectedStoreId } from "../utils/cacheUtil.js";

import {
  getEmailList,
} from "@/libs/api/email-config";

export default {
  name: "",
  components: {
    pagination,
  },
  props: {
    selectedEmail: {
      required: true,
      type: Array,
      default: []
    },
  },
  inject: ['storeQueryType'],
  data() {
    return {
      dialogVisible: false,
      searchParams: {
        no: "",
        userName: "",
        email: "",
        orders: [],
      },
      pageParams: {
        storeId: getSelectedStoreId(),
        storeQueryType: this.storeQueryType,
        current: 1, //当前页
        size: 10, //单页显示最大数
        total: 0,
        userName: '',
        emailAccount: '',
        pageSizeArr: ["10", "20", "50", "100", "200", "500", "1000"],
        isEffect: 0
      },
      dataList: [],
      currentRows: [],
    };
  },
  created() {},
  mounted() {
    this.getData()
  },
  computed: {
    dataA: function (val) {},
  },
  watch: {
    selectedEmail(val) {
      console.log('=========')
      console.log(val)
      let changeRows = this.currentRows.filter(item => val.indexOf(item.emailAccount)>-1)
      console.log(changeRows)
      
    },
    dialogVisible(val) {
      if (val) {
        this.getData()
      }
    },
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    // 获取数据
    getData() {
      this.dataList = []
      getEmailList(this.pageParams).then(res => {
        console.log(res)
        this.pageParams.total = res.data.data.total
        this.dataList = res.data.data.records
      })
    },
    
    // 排序
    handleSort(column) {
      let arr = [];
      if (column.order) {
        let str1 = column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ":" + column.prop;
        arr.push(str1);
      } else {
        arr = "";
      }
      this.searchParams.orders = arr;
      this.getData();
    },
    //
    handleCheck() {},
    // 选择数据
    handleSelectionChange(selection) {
      console.log(selection)
      this.currentRows = selection
    },
    showmessage() {
      this.$message({
        message: "please select img!",
        type: "success",
      });
      this.$message({
        message: "please select img!",
        type: "warning",
      });
      this.$message.error("错误提示");
    },
    // 
    onSubmit() {
      console.log(this.currentRows)
      this.$emit('submitSelectedData',this.currentRows)
      this.close()
      this.$refs.singleTableRef.clearSelection();
    }
  },
};
</script>
<style lang="scss" scoped>
.email-list {
  // 重置样式
  ::v-deep .el-table--border .el-table__cell {
    border-right: 1px solid #ebeef5 !important;
  }
}
.email-list ::v-deep {
  .el-table
    .el-table__fixed-header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    display: none;
  }
}
</style>